import React, {
  useEffect,
  Fragment,
  useContext,
  useState,
  useLayoutEffect,
} from "react"
import { useLocation } from "@reach/router"
import { pageLoad } from "../../components/utility/utils"
import { StateContext } from "../../contextApi"
import ErrorModal from "../modals/errorModal"
import SuccessModal from "../modals/successModal"
import useDeviceDetect from "../utility/useDeviceDetect"
import FirebaseWrapper from "../wrapper/firebaseWrapper"
import NaijaActive from "../../assets/img/flags/naijaActive-c.inline.svg"
import UnitedKingdom from "../../assets/img/flags/uk-c.inline.svg"
import Panel from "./pages/mobile/panel"

const NoHeaderFooterLayout = ({ children }) => {
  const [{ error, success }] = useContext(StateContext)
  const { isMobile } = useDeviceDetect()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState("")
  const [activeCountryTab, setActiveCountryTab] = useState(false)
  const [selectedItem, setSelectedItem] = useState("")
  const [selectIndex, setSelectIndex] = useState(null)
  const [show, setShow] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(
    location?.pathname.includes("/en-ng/legal") ? 1 : 2
  )
  const [hide, setHide] = useState("")

  const darkTheme =
    typeof window !== "undefined"
      ? localStorage.getItem("dark")?.toString()
      : null
  const checkingDarkTheme = darkTheme === "true"

  function onChange(isVisible) {
    setHide(isVisible)
  }

  const homeSupportedUrls = [
    "/mobile/ng/legal/privacy-policy/",
    "/mobile/uk/legal/privacy-policy/",
    "/mobile/ng/legal/terms/",
    "/mobile/uk/legal/terms/",
    "/mobile/ng/legal/terms/business/",
    "/mobile/ng/legal/information-security-policy/",
    "/mobile/uk/legal/complaints-policy/",
    "/mobile/ng/legal/cookie-policy/",
    "/mobile/uk/legal/cookie-policy/",
    "/mobile/ng/legal/disclaimer/investments/",
    "/mobile/ng/legal/disclaimer/giftCards/",
    "/mobile/ng/legal/faq/",
    "/mobile/uk/legal/faq/",
    "/mobile/ng/legal/faq/openApi/",
    "/mobile/ng/legal/faq/business/",
  ]

  const activeHomeTab = homeSupportedUrls?.filter(element => {
    return location?.pathname?.includes(element)
  })

  const legalSupportedUrls = ["/mobile/ng/legal", "/mobile/uk/legal"]

  const legalActiveHomeTab = legalSupportedUrls?.filter(element => {
    return location?.pathname?.includes(element)
  })

  let modal
  switch (true) {
    case error:
      modal = <ErrorModal />
      break
    case success.status:
      modal = <SuccessModal />
      break
    default:
      modal = null
      break
  }

  const activateTab = index => {
    setActiveTab(prevActiveTab => (prevActiveTab === index ? -1 : index))
    // setSelectedItem("");
  }

  const toggleActiveItem = key => {
    setSelectedItem(key)
  }

  const openTab = index => {
    setActiveCountryTab(prevActiveTab => (prevActiveTab === index ? -1 : index))
    setSelectedItem("")
  }

  const toggleCountry = key => {
    setSelectedItem(key)
    setSelectedCountry(key)
  }

  const countries = [
    {
      heading: "Nigeria",
      image: <NaijaActive />,
      list: [
        {
          title: "Nigeria",
          link: "/mobile/ng/legal/",
          id: 1,
          image: <NaijaActive />,
        },
        {
          title: "United Kingdom",
          link: "/mobile/uk/legal/",
          id: 2,
          image: <UnitedKingdom />,
        },
      ],
    },
  ]

  const panels = [
    {
      heading: "Terms & Conditions",
      list: [
        ...(selectedCountry === 1
          ? [
              {
                title: "Retail",
                link: "/mobile/ng/legal/terms/",
                id: 3,
              },
            ]
          : [
              {
                title: "Terms & Conditions",
                link: "/mobile/uk/legal/terms/",
                id: 4,
              },
            ]),

        ...(selectedCountry === 1
          ? [
              {
                title: "Business",
                link: "/mobile/ng/legal/terms/business/",
                id: 5,
              },
              {
                title: "Cards",
                link: "/mobile/ng/legal/cards/",
                id: 6,
              },
            ]
          : []),
      ],
    },
    {
      heading: "Policies",
      list: [
        ...(selectedCountry === 1
          ? [
              {
                title: "Privacy Policy",
                link: "/mobile/ng/legal/privacy-policy/",
                id: 8,
              },
            ]
          : [
              {
                title: "Privacy Policy",
                link: "/mobile/uk/legal/privacy-policy/",
                id: 9,
              },
            ]),

        ...(selectedCountry === 1
          ? [
              {
                title: "Information Security Policy",
                link: "/mobile/ng/legal/information-security-policy/",
                id: 10,
              },
            ]
          : []),

        ...(selectedCountry === 1
          ? []
          : [
              {
                title: "Complaints",
                link: "/mobile/uk/legal/complaints-policy/",
                id: 11,
              },
            ]),

        ...(selectedCountry === 1
          ? [
              {
                title: "Cookie Policy",
                link: "/mobile/ng/legal/cookie-policy/",
                id: 12,
              },
            ]
          : [
              {
                title: "Cookie Policy",
                link: "/mobile/uk/legal/cookie-policy/",
                id: 13,
              },
            ]),
      ],
    },
    ...(selectedCountry === 1
      ? [
          {
            heading: "Disclaimer",
            list: [
              {
                title: "Investments",
                link: "/mobile/ng/legal/disclaimer/investments/",
                id: 14,
              },
              {
                title: "Giftcards",
                link: "/mobile/ng/legal/disclaimer/giftCards/",
                id: 15,
              },
            ],
          },
        ]
      : []),

    {
      heading: "FAQs",
      list: [
        ...(selectedCountry === 1
          ? [
              {
                title: "FAQs",
                link: "/mobile/ng/legal/faq/",
                id: 16,
              },
            ]
          : [
              {
                title: "FAQs",
                link: "/mobile/uk/legal/faq/",
                id: 17,
              },
            ]),
        ...(selectedCountry === 1
          ? [
              {
                title: "Open Api FAQs",
                link: "/mobile/ng/legal/faq/openApi/",
                id: 18,
              },
            ]
          : []),
        ...(selectedCountry === 1
          ? [
              {
                title: "Kuda for Business FAQs",
                link: "/mobile/ng/legal/faq/business/",
                id: 19,
              },
            ]
          : []),
      ],
    },
  ]

  const selectedIndex = () => {
    let value = undefined
    panels.find((item, index) => {
      return item.list.find(listItem => {
        if (listItem.link === location.pathname) {
          value = { activeIndex: index, ...listItem }
          // return value
        }
        // return false;
      })
    })
    return value
  }

  useEffect(() => {
    if (
      location?.pathname.includes("/mobile/ng/legal/faq/") ||
      location?.pathname.includes("/mobile/uk/legal/faq/") ||
      location?.pathname.includes("/mobile/ng/legal/terms/") ||
      location?.pathname.includes("/mobile/ng/legal/privacy-policy/") ||
      location?.pathname.includes("/mobile/uk/legal/privacy-policy/") ||
      location?.pathname.includes(
        "/mobile/ng/legal/information-security-policy/"
      ) ||
      location?.pathname.includes("/mobile/uk/legal/complaints-policy/") ||
      location?.pathname.includes("/mobile/ng/legal/disclaimer/investments/") ||
      location?.pathname.includes("/mobile/ng/legal/disclaimer/giftCards/") ||
      location?.pathname.includes("/mobile/uk/legal/complaints-policy/") ||
      location?.pathname.includes("/mobile/uk/legal/cookie-policy/") ||
      location?.pathname.includes("/mobile/ng/legal/cookie-policy/") ||
      location?.pathname.includes("/mobile/uk/legal/terms/") ||
      location?.pathname.includes("/mobile/uk/legal/") ||
      location?.pathname.includes("/mobile/ng/legal/")
    ) {
      setShow(true)
    }
  }, [location])

  useLayoutEffect(() => {
    if (
      location?.pathname &&
      location?.pathname.includes("/mobile/ng/legal/")
    ) {
      setSelectedCountry(1)
      setSelectedItem(selectedIndex()?.id)
      activateTab(selectedIndex()?.activeIndex)
      setSelectIndex(selectedIndex()?.activeIndex)
    } else if (
      location?.pathname &&
      location?.pathname.includes("/mobile/uk/legal/")
    ) {
      setSelectedCountry(2)
      setSelectedItem(selectedIndex()?.id)
      activateTab(selectedIndex()?.activeIndex)
      setSelectIndex(selectedIndex()?.activeIndex)
    }
  }, [location?.pathname])

  useEffect(() => {
    if (location?.search === "?=dark") {
      localStorage.setItem("dark", "true")
    } else {
      localStorage.setItem("dark", "false")
    }
  }, [])

  useEffect(() => {
    pageLoad()
    window.scrollTo(0, 0)
  }, [])

  return (
    <FirebaseWrapper>
      <div
        className={`kuda-entry ${checkingDarkTheme ? "kuda-dark--theme" : ""} ${
          isMobile.toString().includes("iPhone") ? "white" : "white"
        }`}
      >
        <div
          className={`kuda-entry--wrap ${
            checkingDarkTheme ? "kuda-dark--theme" : ""
          }`}
        >
          <React.Fragment>
            {/***** POLICY SIDEBAR *****/}
            <>
              <div
                className={`policy-sidebar mobile-sidebar ${
                  show && !hide
                    ? "visible-anim fadeInUp animated"
                    : "fade-anim fadeOutDown animated"
                } ${legalActiveHomeTab.length > 0 && "display"} `}
              >
                <div
                  className="accordion country-sidebar country-mobile-sidebar"
                  role="tablist"
                >
                  {countries.map(({ heading, list, image }, index) => (
                    <Panel
                      key={index + list}
                      activeTab={activeCountryTab}
                      index={index}
                      question={
                        list.find(item => item.id === selectedCountry)?.title ||
                        heading
                      }
                      answer={list.filter(a => a.id !== selectedCountry)}
                      activateTab={() => openTab(index)}
                      setSelectedItem={e => toggleCountry(e)}
                      selectedItem={selectedItem}
                      icon
                      QuestionIcon={
                        list.find(item => item.id === selectedCountry)?.image ||
                        image
                      }
                      questionStyles={"mr-3"}
                      textStyles={"color-black"}
                    />
                  ))}
                </div>
                <div
                  className={`accordion panel-sidebar panel-mobile-sidebar ${
                    activeHomeTab.length > 0 && "tab-item"
                  }`}
                  role="tablist"
                >
                  {panels.map(({ heading, list }, index) => (
                    <Panel
                      key={index + list}
                      activeTab={activeTab}
                      index={index}
                      question={heading}
                      answer={list}
                      activateTab={e => activateTab(e)}
                      setSelectedItem={e => toggleActiveItem(e, index)}
                      selectedItem={selectedItem}
                      selectIndex={selectIndex}
                      showGrey={list.find(
                        item => item.title === selectedIndex()?.title
                      )}
                    />
                  ))}
                </div>
              </div>
            </>

            <div
                className={`kuda-preview container no-margin--top ${
                  activeHomeTab.length > 0 ? "no-padding" : ""
                } ${
                  legalActiveHomeTab.length > 0 ? "no-pad is-mobile-legal" : ""
                } `}
            >
              {children}
            </div>
          </React.Fragment>
        </div>
      </div>
    </FirebaseWrapper>
  )
}

export default NoHeaderFooterLayout
